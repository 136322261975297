<template>
  <div class="py-10 lg:py-28 overflow-hidden" :class="data.background === 'gray' ? 'bg-gray-200' : 'bg-white'">
    <div class="container grid-default">
      <Heading
        semantic
        class="col-span-6 mb-6 text-3xl font-light text-blue-950 lg:col-start-2 lg:col-end-11 lg:mb-20 lg:text-6xl"
      >
        {{ data.title }}
      </Heading>
      <div ref="sectionRef" class="col-span-6 lg:col-span-12">
        <LazyPositions v-if="isSectionVisible" :data="data.positionslot" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionPositionRecord } from '~/types/generated'

defineProps({
  data: {
    type: Object as PropType<SectionPositionRecord>,
    default: () => {}
  }
})

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)

const { stop } = useIntersectionObserver(
  sectionRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isSectionVisible.value = true
    }
  },
  {
    threshold: 0
  }
)

onUnmounted(() => {
  stop()
})
</script>
